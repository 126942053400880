import React from "react";


const data = [
    {
        title: "Срочный вызов",
        paragraph:"Скорая медицинская помощь взрослым и детям. Профессиональная бригада с врачом прибудет в течение 20 минут. \n" +
            "\n" +
            "Квалифицированный врач проведет осмотр на месте, снимет симптомы, облегчит самочувствие и назначит правильное лечение. \n" +
            "\n" +
            "Врач останется рядом, дождавшись стабилизации состояния. По состоянию здоровья будет предложено сопровождение в клинику для дальнейшей диагностики. "
    },
    {
        title: "Транспортировка",
        paragraph:"Все машины оборудованы гидравлическими системами FERNO, которые позволяют с комфортом, без тряски доставить пациентов до медицинского учреждения.\n" +
            "\n" +
            " Перевозка пациентов проводится на всей территории КР и за её пределами\n" +
            "\n" +
            " В случае резкого ухудшения состояния, все реанимобили оснащены необходимым оборудованием и медикаментами для стабилизации состояния организма. "
    },
    {
        title: "Снятие интоксикации",
        paragraph:"Комплексная восстановительная система детоксикации организма с применением витаминотерапии, ноотропных и гепатопротекторных препаратов, позволит в короткий срок вывести из организма алкоголь и продукты его распада.\n" +
            "\n" +
            "После очистки наблюдается нормализация общего состояния, работы сердечно- сосудистой системы, снижение артериального давления и чувства тревоги ,апатии , уменьшение эффекта «похмелья» "
    },
    {
        title: "Санавиация",
        paragraph:"Благодаря регулярным или чартерным рейсам осуществляем быструю и комфортабельную транспортировку пациента в медицинское учреждение. \n" +
            "\n" +
            "Вся авиация оборудована средствами для ухода и поддержания нужного уровня жизнедеятельности организма.  "
    },
    {
        title: "Дежурство на мероприятиях",
        paragraph:"Постоянное присутствие квалифицированной и подготовленной бригады неотложной медицинской помощи на мероприятиях скопления людей, вне зависимости от продолжительности. концерты· корпоративы · свадьбы · похороны · спортивные мероприятия · тренинги · выставки Детские мероприятия"
    },
    {
        title: "Вызов узких специалистов",
        paragraph:"Нарколог Уролог Хирург Невропатолог Психиатр Травматолог  Нефролог Кардиолог  Реаниматолог   Педиатр Семейный врач"
    }

];

class Accordion extends React.Component {
    render() {
        return (
            <div {...{ className: "wrapper" }}>
                <ul {...{ className: "accordion-list" }}>
                    {data.map((data, key) => {
                        return (
                            <li {...{ className: "accordion-list__item", key }}>
                                <AccordionItem {...data} />
                            </li>
                        );
                    })}
                </ul>
            </div>
        );
    }
}

class AccordionItem extends React.Component {
    state = {
        opened: false
    };

    render() {
        const {
            props: { paragraph, title },
            state: { opened }
        } = this;

        return (
            <div
                {...{
                    className: `accordion-item, ${opened && "accordion-item--opened"}`,
                    onClick: () => {
                        this.setState({ opened: !opened });
                    }
                }}
            >
                <div {...{ className: "accordion-item__line" }}>
                    <h3 {...{ className: "accordion-item__title" }}>{title}</h3>
                    <span {...{ className: "accordion-item__icon" }} />
                </div>
                <div {...{ className: "accordion-item__inner" }}>
                    <div {...{ className: "accordion-item__content" }}>
                        <p {...{ className: "accordion-item__paragraph" }}>{paragraph}</p>
                    </div>
                </div>
            </div>
        );
    }
}

export default Accordion;
